import React, { useState, useEffect } from 'react';
import './App.css';
import { analytics } from './firebase';  // Import Firebase analytics

function App() {
  // State to track if the user has accepted the cookie policy
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  // Check if the user has already accepted the cookie policy
  useEffect(() => {
    const consent = getCookie('cookieConsent');
    if (!consent) {
      setShowCookiePopup(true); // Show popup if no consent cookie is found
    }

    // Initialize Firebase Analytics when the app loads
    console.log('Firebase Analytics initialized:', analytics);

  }, []);

  // Utility function to get cookies
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // Utility function to set cookies
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  // Handle cookie consent acceptance
  const handleAcceptCookies = () => {
    setCookie('cookieConsent', 'true', 365); // Store consent for 1 year
    setShowCookiePopup(false); // Hide the popup after acceptance
  };

  return (
    <div className="container">
      <div className="content">
        <img
          src="https://i.imgur.com/mRBOI4M.png"
          alt="Main Logo"
          className="main-image"
        />
        <h1 className="title">Coming Soon!</h1>
        <p className="description">
          We're working hard to bring you an amazing experience. Stay tuned!
        </p>
        <a
          href="https://discord.gg/comingsoooon"
          target="_blank"
          rel="noopener noreferrer"
          className="discord-link"
        >
          <button className="discord-button">
            <img
              src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png"
              alt="Discord Logo"
              className="discord-logo"
            />
            Join Discord
          </button>
        </a>

        {/* Kinguin Text */}
        <p className="kinguin-text">for Kinguin</p>

        {/* Cookie Consent Popup */}
        {showCookiePopup && (
          <div className="cookie-popup">
            <p>
              We use cookies to ensure you get the best experience on our website. By continuing, you accept our use of cookies.
            </p>
            <button onClick={handleAcceptCookies} className="accept-cookies-btn">
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
